import { parseWithZod } from '@conform-to/zod'
import { invariantResponse } from '@epic-web/invariant'
import { json, type LoaderFunctionArgs, type ActionFunctionArgs, type HeadersFunction, type LinksFunction, type MetaFunction } from '@remix-run/node'
import { Links, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData, useLocation, /* useMatches, */ useNavigate } from '@remix-run/react'
import { withSentry } from '@sentry/remix'
import { RouterProvider } from 'react-aria-components'
import { HoneypotProvider } from 'remix-utils/honeypot/react'
import { z } from 'zod'
import { GeneralErrorBoundary } from '#app/old/components/error-boundary.tsx'
import { useToast } from '#app/old/components/notifications/toaster.tsx'
import { EpicProgress } from '#app/old/components/status/progress-bar.tsx'
import { Grid } from './components/layout/grid.tsx'
//import { SearchBar } from './components/search-bar.tsx'
import { href as iconsHref } from './components/media/icon.tsx'
import { EpicToaster } from './old/components/notifications/sonner.tsx'
import { Footer } from './old/patterns/footer.tsx'
import { Header, useOptimisticThemeMode } from './old/patterns/header.tsx'
import tailwindStyleSheetUrl from './styles/tailwind.css?url'
import { getUserId, logout } from './utils/auth.server.ts'
import { ClientHintCheck, getHints, useHints } from './utils/client-hints.tsx'
import { prisma } from './utils/db.server.ts'
import { getEnv } from './utils/env.server.ts'
import { FathomScript, useTrackPageview } from './utils/fathom.tsx'
import { honeypot } from './utils/honeypot.server.ts'
import { combineHeaders, getDomainUrl } from './utils/misc.tsx'
import { useNonce } from './utils/nonce-provider.ts'
import { useRequestInfo } from './utils/request-info.ts'
import { type Theme, setTheme, getTheme } from './utils/theme.server.ts'
import { makeTimings, time } from './utils/timing.server.ts'
import { getToast } from './utils/toast.server.ts'
//import { useOptionalUser } from './utils/user.ts'

export const handle = {
	breadcrumb: 'Home',
}

export const links: LinksFunction = () => {
	return [
		{ rel: 'mask-icon', href: '/favicons/mask-icon.svg' },
		{ rel: 'alternate icon', type: 'image/png', href: '/favicons/favicon-32x32.png' },
		{ rel: 'apple-touch-icon', href: '/favicons/apple-touch-icon.png' },
		{ rel: 'manifest', href: '/site.webmanifest', crossOrigin: 'use-credentials' } as const, // necessary to make typescript happy
		// Preload svg sprite as a resource to avoid render blocking
		{ rel: 'preload', href: iconsHref, as: 'image' },
		//{ rel: 'preload', href: tailwindStyleSheetUrl, as: 'style' },
		//These should match the css preloads above to avoid css as render blocking resource
		{ rel: 'icon', type: 'image/svg+xml', href: '/favicons/favicon.svg' },
		{ rel: 'stylesheet', href: tailwindStyleSheetUrl },
	].filter(Boolean)
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
	return [
		{ title: data ? 'VerveUI, a UI library for React' : 'Error | VerveUI' },
		{
			name: 'description',
			content: `Ship faster with beautiful components. Complete, composable, production-ready components that consume a beautiful, customizable, production-ready design system.`,
		},
		// Add open graph meta tags
		{ property: 'og:type', content: 'website' },
		{ property: 'og:title', content: 'VerveUI, a UI library for React' },
		{
			property: 'og:description',
			content: 'Ship faster with beautiful components. Complete, composable, production-ready components that consume a beautiful, customizable, production-ready design system.',
		},
		{ property: 'og:image', content: '/img/verveui-social.png' },
		{ property: 'og:image:type', content: 'image/png' },
		{ property: 'og:url', content: 'https://verveui.pro' },
		{ name: 'twitter:card', content: 'summary_large_image' },
		{ name: 'twitter:site', content: '@verveui' },
		{ name: 'twitter:creator', content: '@andrecasaldev' },
	]
}

export async function loader({ request }: LoaderFunctionArgs) {
	const timings = makeTimings('root loader')
	const userId = await time(() => getUserId(request), { timings, type: 'getUserId', desc: 'getUserId in root' })

	const user =
		userId ?
			await time(
				() =>
					prisma.user.findUniqueOrThrow({
						select: {
							id: true,
							paid: true,
							email: true,
							roles: {
								select: {
									name: true,
									permissions: {
										select: { entity: true, action: true, access: true },
									},
								},
							},
						},
						where: { id: userId },
					}),
				{ timings, type: 'find user', desc: 'find user in root' },
			)
		:	null
	if (userId && !user) {
		console.info('something weird happened')
		// something weird happened... The user is authenticated but we can't find
		// them in the database. Maybe they were deleted? Let's log them out.
		await logout({ request, redirectTo: '/' })
	}
	const { toast, headers: toastHeaders } = await getToast(request)
	const honeyProps = honeypot.getInputProps()

	return json(
		{
			user,
			requestInfo: {
				hints: getHints(request),
				origin: getDomainUrl(request),
				path: new URL(request.url).pathname,
				userPrefs: {
					theme: getTheme(request),
				},
			},
			ENV: getEnv(),
			toast,
			honeyProps,
		},
		{
			headers: combineHeaders({ 'Server-Timing': timings.toString() }, toastHeaders),
		},
	)
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
	const headers = {
		'Server-Timing': loaderHeaders.get('Server-Timing') ?? '',
	}
	return headers
}

export const ThemeFormSchema = z.object({
	theme: z.enum(['system', 'light', 'dark']),
})

export async function action({ request }: ActionFunctionArgs) {
	const formData = await request.formData()
	const submission = parseWithZod(formData, { schema: ThemeFormSchema })

	invariantResponse(submission.status === 'success', 'Invalid theme received')

	const { theme } = submission.value

	const responseInit = { headers: { 'set-cookie': setTheme(theme) } }
	return json({ result: submission.reply() }, responseInit)
}

function Document({ children, nonce, theme = 'light', env = {} }: { children: React.ReactNode; nonce: string; theme?: Theme; env?: Record<string, string> }) {
	return (
		<html lang="en" className="h-full overflow-x-hidden" data-theme={theme}>
			<head>
				<ClientHintCheck nonce={nonce} />
				<Meta />
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width,initial-scale=1" />
				<Links />
				<style>
					{/* This prevents FOUC. Make sure to add the opposite at the end of your stylesheet. */}
					{`@layer theme { html { visibility: hidden; opacity:0; } }`}
				</style>
			</head>
			<body className="bg-background text-foreground">
				{children}
				<script
					nonce={nonce}
					dangerouslySetInnerHTML={{
						__html: `window.ENV = ${JSON.stringify(env)}`,
					}}
				/>
				<ScrollRestoration nonce={nonce} />
				<Scripts nonce={nonce} />
			</body>
		</html>
	)
}

function App() {
	const data = useLoaderData<typeof loader>()
	const nonce = useNonce()
	//const user = useOptionalUser()
	const theme = useTheme()
	//const matches = useMatches()
	//const isOnSearchPage = matches.find(m => m.id === 'routes/users+/index')
	//const searchBar = isOnSearchPage ? null : <SearchBar status="idle" />
	useToast(data.toast)
	useTrackPageview()
	const location = useLocation()

	return (
		<Document nonce={nonce} theme={location.pathname === '/' ? 'dark' : theme} env={data.ENV}>
			<Grid justify="stretch" align="stretch" className="min-h-screen [grid-template-rows:auto_1fr_auto]">
				<Header data={data} />
				<Outlet />
				<Footer />
			</Grid>
			<EpicToaster closeButton position="top-center" theme={theme} />
			<EpicProgress />
			<FathomScript nonce={nonce} data-site={data.ENV.FATHOM_ANALYTICS_SITE_ID} />
		</Document>
	)
}

function AppWithProviders() {
	const data = useLoaderData<typeof loader>()
	const navigate = useNavigate()
	return (
		<HoneypotProvider {...data.honeyProps}>
			<RouterProvider navigate={navigate}>
				<App />
			</RouterProvider>
		</HoneypotProvider>
	)
}

export default withSentry(AppWithProviders)

/**
 * @returns the user's theme preference, or the client hint theme if the user
 * has not set a preference.
 */
export function useTheme() {
	const hints = useHints()
	const requestInfo = useRequestInfo()
	const optimisticMode = useOptimisticThemeMode()
	if (optimisticMode) {
		return optimisticMode === 'system' ? hints.theme : optimisticMode
	}
	return requestInfo.userPrefs.theme ?? hints.theme
}

export function ErrorBoundary() {
	// the nonce doesn't rely on the loader so we can access that
	const nonce = useNonce()

	// NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
	// likely failed to run so we have to do the best we can.
	// We could probably do better than this (it's possible the loader did run).
	// This would require a change in Remix.

	// Just make sure your root route never errors out and you'll always be able
	// to give the user a better UX.

	return (
		<Document nonce={nonce}>
			<GeneralErrorBoundary />
		</Document>
	)
}
