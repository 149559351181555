import { Container } from '#app/components/layout/container.tsx'
import { VisuallyHidden } from '#app/components/layout/visually-hidden.tsx'
import { Icon } from '#app/components/media/icon'
import { H2 } from '#app/components/typography/h2.tsx'
import { P } from '#app/components/typography/p.tsx'

export function Footer() {
	return (
		<Container>
			<footer aria-labelledby="footer-heading" className="gap-fluid-4 flex min-h-[--footer-height] flex-wrap items-center justify-between py-[4px]">
				<VisuallyHidden>
					<H2 id="footer-heading">Footer</H2>
				</VisuallyHidden>
				<P className="text-muted-500 text-xs">&copy; {new Date().getFullYear()} VerveUI. All rights reserved.</P>
				<a href="https://x.com/VerveUI" target="_blank" rel="noreferrer" className="text-muted-600 hover:text-muted-500 -mr-viewport-edge p-viewport-edge rounded-md transition-colors">
					<Icon name="x" className="h-7 w-7" aria-hidden="true" />
					<VisuallyHidden>VerveUI on X</VisuallyHidden>
				</a>
			</footer>
		</Container>
	)
}
