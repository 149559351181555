import { getFormProps, useForm } from '@conform-to/react'
import { parseWithZod } from '@conform-to/zod'
import { type SerializeFrom } from '@remix-run/node'
import { Form, NavLink, useFetcher, useFetchers, useLocation, useSubmit } from '@remix-run/react'
import { useRef } from 'react'
import { Label, Radio, RadioGroup } from 'react-aria-components'
import { toast } from 'sonner'
import { Button } from '#app/components/buttons/button.tsx'
import { Container } from '#app/components/layout/container.tsx'
import { Flex } from '#app/components/layout/flex.tsx'
import { VisuallyHidden } from '#app/components/layout/visually-hidden.tsx'
import { Icon } from '#app/components/media/icon.tsx'
import { Link } from '#app/components/navigation/link.tsx'
import { Dialog, DialogTitle, DialogTrigger } from '#app/components/overlays/dialog.tsx'
import { A } from '#app/components/typography/a.tsx'
import { Hr } from '#app/components/typography/hr.tsx'
import { useCopyToClipboard } from '#app/hooks/useCopyToClipboard.ts'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuPortal,
	DropdownMenuRadioGroup,
	DropdownMenuRadioItem,
	DropdownMenuTrigger,
} from '#app/old/components/navigation/dropdown-menu.tsx'
import { NavigationMenu, NavigationMenuList, NavigationMenuItem, NavigationMenuLink } from '#app/old/components/navigation/navigation-menu.tsx'
import { ContextMenu, ContextMenuContent, ContextMenuItem, ContextMenuTrigger } from '#app/old/components/ovelays/context-menu.tsx'
import { type loader as rootLoader, type action as rootAction, ThemeFormSchema } from '#app/root.tsx'
import { Sidebar } from '#app/routes/docs+/_layout.tsx'
import { type Theme } from '#app/utils/theme.server.ts'

const prefetch = 'intent'

const logoAsSVG = `<svg width="163" height="33" viewBox="0 0 163 33" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path
		d="M45.96 22.5L38.94 8.3H43.44L47.88 17.84C48.14 18.4 48.26 18.84 48.36 19.28H48.44C48.52 18.84 48.66 18.4 48.9 17.84L53.34 8.3H57.86L50.82 22.5H45.96ZM63.6886 19.66H74.1286V22.5H59.6486V8.3H73.9886V11.14H63.6886V13.98H73.6486V16.62H63.6886V19.66ZM88.7663 22.5L85.3863 17.48H81.2862V22.5H77.2463V8.3H86.9263C91.4863 8.3 92.8063 10.64 92.8063 12.8C92.8063 14.38 92.0063 16.24 89.6263 17.02L93.3263 22.5H88.7663ZM81.2862 11V14.9H86.0063C87.9463 14.9 88.5263 13.92 88.5263 12.92C88.5263 11.94 87.9463 11 86.0063 11H81.2862ZM100.12 22.5L93.1002 8.3H97.6002L102.04 17.84C102.3 18.4 102.42 18.84 102.52 19.28H102.6C102.68 18.84 102.82 18.4 103.06 17.84L107.5 8.3H112.02L104.98 22.5H100.12ZM117.849 19.66H128.289V22.5H113.809V8.3H128.149V11.14H117.849V13.98H127.809V16.62H117.849V19.66ZM138.932 8.3H142.992V15.72C142.992 18.12 144.492 19.82 147.292 19.82C149.992 19.82 151.572 18.12 151.572 15.72V8.3H155.612V16.14C155.612 20.24 152.652 23 147.292 23C141.912 23 138.932 20.22 138.932 16.1V8.3ZM162.771 22.5H158.731V8.3H162.771V22.5Z"
		fill="url(#paint0_linear_3053_4071)"
	/>
	<path
		fill-rule="evenodd"
		clip-rule="evenodd"
		d="M35.7139 4.90495C36.7855 3.03439 34.6556 0.514076 32.4879 0.500063L2.47934 0.500062C0.287078 0.485886 -0.633004 2.84004 0.463149 4.7248L15.7979 31.0917C16.894 32.9765 19.6375 32.9676 20.7213 31.0758L35.7139 4.90495ZM32.2828 6.1658C33.0785 4.76573 31.7065 2.88634 30.0872 2.87037L6.08037 2.87037C4.43297 2.85412 3.29625 4.52635 4.11999 5.94271L16.5638 27.3389C17.3876 28.7553 19.4504 28.7451 20.26 27.3207L32.2828 6.1658Z"
		fill="#0A0A0A"
	/>
	<path
		fill-rule="evenodd"
		clip-rule="evenodd"
		d="M28.7709 8.11544C29.4256 7.00479 28.5997 5.62477 27.2754 5.61645L8.74729 5.50004C7.40795 5.49162 6.56518 6.8894 7.23486 8.00848L16.6034 23.6638C17.2731 24.7829 18.9492 24.7776 19.6113 23.6544L28.7709 8.11544ZM26.6747 8.86407C27.1608 8.03277 26.5446 7.00308 25.5553 6.99359L10.6077 6.85031C9.60128 6.84067 8.96571 7.89065 9.46897 8.73161L17.0714 21.4356C17.5746 22.2766 18.8349 22.2705 19.3295 21.4248L26.6747 8.86407Z"
		fill="url(#paint1_linear_3053_4071)"
	/>
	<defs>
		<linearGradient id="paint0_linear_3053_4071" x1="102" y1="20.5" x2="102" y2="10.5" gradientUnits="userSpaceOnUse">
			<stop stop-color="#0A0A0A" />
			<stop offset="1" stop-color="#404042" />
		</linearGradient>
		<linearGradient id="paint1_linear_3053_4071" x1="18" y1="24.5" x2="18" y2="5.5" gradientUnits="userSpaceOnUse">
			<stop stop-color="#0A0A0A" />
			<stop offset="1" stop-color="#111111" stop-opacity="0" />
		</linearGradient>
	</defs>
</svg>
`

export function Header({ data }: { data: SerializeFrom<typeof rootLoader> }) {
	//const mainNavigation = navigation.filter(({ name }) => name === 'Getting Started' || name === 'Components')
	const location = useLocation()

	return (
		<Container>
			<header className="h-[--header-height] py-1">
				<NavigationMenu id="radix-ui-hack" className="h-full w-full">
					<NavigationMenuList className="h-full">
						<Flex gap="5" className="h-full">
							<NavigationMenuItem className="mr-auto">
								<LogoWithContextMenu theme={data.requestInfo.userPrefs.theme} />
							</NavigationMenuItem>
							{data.user ?
								<UserDropdown user={data.user} />
							:	<>
									<NavigationMenuItem>
										<NavigationMenuLink asChild>
											<NavLink
												prefetch={prefetch}
												to="/login"
												className={`after:transition-max-width after:bg-brand-border px-viewport-edge hover:bg-muted-100 relative hidden h-full items-center rounded-md after:absolute after:right-0 after:bottom-0 after:left-0 after:block after:h-1 after:max-w-0 after:rounded-full after:duration-500 aria-[current]:after:max-w-full md:flex`}
											>
												Log in
											</NavLink>
										</NavigationMenuLink>
									</NavigationMenuItem>
									<NavigationMenuItem>
										<NavigationMenuLink asChild>
											<NavLink
												prefetch={prefetch}
												to="/create-account"
												className={`after:transition-max-width after:bg-brand-border px-viewport-edge hover:bg-muted-100 relative hidden h-full items-center rounded-md after:absolute after:right-0 after:bottom-0 after:left-0 after:block after:h-1 after:max-w-0 after:rounded-full after:duration-500 aria-[current]:after:max-w-full md:flex`}
											>
												Create Account
											</NavLink>
										</NavigationMenuLink>
									</NavigationMenuItem>
								</>
							}
							{location.pathname !== '/' ?
								<ThemeSwitchDropdown id="desktop" userPreference={data.requestInfo.userPrefs.theme} />
							:	null}
							<NavigationMenuItem>
								<DialogTrigger>
									<NavigationMenuLink asChild>
										<Button variant="ghost" className="-mr-viewport-edge px-viewport-edge hover:bg-muted-100 flex h-full items-center">
											<Icon name="bars-3" className="h-6 w-6" />
											<VisuallyHidden>Menu</VisuallyHidden>
										</Button>
									</NavigationMenuLink>
									<Dialog side="right">
										{({ close }) => (
											<>
												<DialogTitle hidden>Menu</DialogTitle>
												<Flex orientation="vertical" gap="15">
													<div className="block md:hidden">
														<Label className="text-xs uppercase">Account</Label>
														<Hr className="mt-0 mb-2" />
														{data.user ?
															<>
																<Flex orientation="vertical" gap="2">
																	<Flex orientation="vertical" className="mb-2 text-xs">
																		<span className="text-muted-500">Logged in as</span>
																		<span className="text-muted-500">{data.user.email}</span>
																	</Flex>
																	<A
																		href="/profile"
																		prefetch={prefetch}
																		className="hover:bg-muted-100 text-muted-500 -ml-2 block flex w-full items-center justify-between rounded-md p-2 text-xs no-underline data-[disabled=true]:cursor-default"
																		onClick={close}
																		autoFocus={true}
																	>
																		Settings
																		{/* <span className="bg-muted-300 text-muted-600 ml-1 rounded-md py-0.5 px-2 text-xs">{data.user.paid ? 'PRO' : 'FREE'}</span> */}
																	</A>
																	{/* {data.user.paid ? null : (
																<A href="/pro" className="button md secondary mt-4 w-full no-underline" onClick={close}>
																	Upgrade to Pro
																</A>
															)} */}
																	<Form method="POST" action="/logout">
																		<Button
																			type="submit"
																			variant="ghost"
																			className="hover:bg-muted-100 text-muted-500 -ml-2 block flex w-full items-center justify-between rounded-md p-2 text-xs font-normal no-underline data-[disabled=true]:cursor-default"
																			onPress={close}
																		>
																			Log out
																			<Icon name="exit" className="mr-3" />
																		</Button>
																	</Form>
																</Flex>
															</>
														:	<>
																<A
																	href="/login"
																	prefetch={prefetch}
																	className="hover:bg-muted-100 text-muted-500 -ml-2 block w-full rounded-md p-2 text-xs no-underline data-[disabled=true]:cursor-default"
																	onClick={close}
																>
																	Log in
																</A>
																<A
																	href="/create-account"
																	prefetch={prefetch}
																	className="hover:bg-muted-100 text-muted-500 -ml-2 block w-full rounded-md p-2 text-xs no-underline data-[disabled=true]:cursor-default"
																	onClick={close}
																>
																	Create Account
																</A>
															</>
														}
													</div>
													<ThemeSwitch id="mobile" userPreference={data.requestInfo.userPrefs.theme} />
													<Sidebar onPress={close} />
												</Flex>
											</>
										)}
									</Dialog>
								</DialogTrigger>
							</NavigationMenuItem>
						</Flex>
					</NavigationMenuList>
				</NavigationMenu>
			</header>
		</Container>
	)
}

/**
 * If the user's changing their theme mode preference, this will return the
 * value it's being changed to.
 */
export function useOptimisticThemeMode() {
	const fetchers = useFetchers()
	const themeFetcher = fetchers.find(f => f.formAction === '/')

	if (themeFetcher && themeFetcher.formData) {
		const submission = parseWithZod(themeFetcher.formData, {
			schema: ThemeFormSchema,
		})

		if (submission.status === 'success') {
			return submission.value.theme
		}
	}
}

function ThemeSwitch({ id, userPreference }: { id?: string; userPreference?: Theme | null }) {
	const fetcher = useFetcher<typeof rootAction>()
	const [form] = useForm({ id: 'theme-switch', lastResult: fetcher.data?.result })

	const optimisticMode = useOptimisticThemeMode()
	const mode = optimisticMode ?? userPreference ?? 'system'
	const nextMode =
		mode === 'system' ? 'light'
		: mode === 'light' ? 'dark'
		: 'system'

	const handleSelection = (theme: string) => {
		fetcher.submit({ theme }, { method: 'POST' })
	}

	return (
		<fetcher.Form method="POST" {...getFormProps(form)} className="block md:hidden">
			<input type="hidden" name="theme" value={nextMode} />
			<Flex orientation="vertical" gap="2">
				<Label className="text-xs uppercase">Theme</Label>
				<Hr className="mt-0 mb-2" />
				<RadioGroup className="flex flex-col" value={mode} onChange={handleSelection}>
					<Radio value="light" className="text-muted-500 hover:bg-muted-100 -ml-2 flex items-center justify-between gap-4 rounded-md p-2 text-xs data-[selected=true]:font-extrabold">
						Light
						<Icon name="sun" className="h-3" />
					</Radio>
					<Radio value="dark" className="text-muted-500 hover:bg-muted-100 -ml-2 flex items-center justify-between gap-4 rounded-md p-2 text-xs data-[selected=true]:font-extrabold">
						Dark
						<Icon name="moon" className="h-3" />
					</Radio>
					<Radio value="system" className="text-muted-500 hover:bg-muted-100 -ml-2 flex items-center justify-between gap-4 rounded-md p-2 text-xs data-[selected=true]:font-extrabold">
						System
						<Icon name="desktop" className="h-3" />
					</Radio>
				</RadioGroup>
			</Flex>
		</fetcher.Form>
	)
}
function ThemeSwitchDropdown({ id, userPreference }: { id?: string; userPreference?: Theme | null }) {
	const fetcher = useFetcher<typeof rootAction>()
	const [form] = useForm({ id: 'theme-switch', lastResult: fetcher.data?.result })

	const optimisticMode = useOptimisticThemeMode()
	const mode = optimisticMode ?? userPreference ?? 'system'
	const nextMode =
		mode === 'system' ? 'light'
		: mode === 'light' ? 'dark'
		: 'system'
	const modeLabel = {
		system: (
			<>
				<Icon name="laptop" />
				<VisuallyHidden>System</VisuallyHidden>
			</>
		),
		light: (
			<>
				<Icon name="sun" />
				<VisuallyHidden>Light</VisuallyHidden>
			</>
		),
		dark: (
			<>
				<Icon name="moon" />
				<VisuallyHidden>Dark</VisuallyHidden>
			</>
		),
	}

	const handleSelection = (theme: string) => {
		fetcher.submit({ theme }, { method: 'POST' })
	}

	return (
		<fetcher.Form method="POST" {...getFormProps(form)} className="hidden md:block">
			<input type="hidden" name="theme" value={nextMode} />
			<DropdownMenu>
				<DropdownMenuTrigger className="px-viewport-edge data-[state=open]:bg-muted-100 hover:bg-muted-100 gap-fluid-4 box-content flex h-full cursor-pointer items-center justify-center rounded-lg">
					{modeLabel[mode as keyof typeof modeLabel]} <Icon name="chevron-down" className="h-4 w-4" />
				</DropdownMenuTrigger>

				<DropdownMenuPortal>
					<DropdownMenuContent className="w-40" hideWhenDetached>
						<DropdownMenuRadioGroup value={mode} onValueChange={handleSelection}>
							<DropdownMenuRadioItem
								value="light"
								className="min-h-tap text-size-sm text-muted-700 focus-visible:bg-muted-100 flex justify-between rounded-lg py-2 px-4 focus-visible:outline-none"
							>
								<div className="flex items-center gap-4">
									<Icon name="sun" className="h-4" />
									Light
								</div>
							</DropdownMenuRadioItem>
							<DropdownMenuRadioItem
								value="dark"
								className="min-h-tap text-size-sm text-muted-700 focus-visible:bg-muted-100 flex justify-between py-2 px-4 focus-visible:outline-none"
							>
								<div className="flex items-center gap-4">
									<Icon name="moon" className="h-4" />
									Dark
								</div>
							</DropdownMenuRadioItem>
							<DropdownMenuRadioItem
								value="system"
								className="min-h-tap text-size-sm text-muted-700 focus-visible:bg-muted-100 flex justify-between py-2 px-4 focus-visible:outline-none"
							>
								<div className="flex items-center gap-4">
									<Icon name="desktop" className="h-4" />
									System
								</div>
							</DropdownMenuRadioItem>
						</DropdownMenuRadioGroup>
					</DropdownMenuContent>
				</DropdownMenuPortal>
			</DropdownMenu>
		</fetcher.Form>
	)
}

function LogoWithContextMenu({ theme }: { theme: Theme | null }) {
	const [, copy] = useCopyToClipboard()
	return (
		<ContextMenu>
			<ContextMenuTrigger>
				<NavigationMenuLink asChild>
					<NavLink prefetch={prefetch} to="/" className="-ml-viewport-edge px-viewport-edge hover:bg-muted-100 flex h-full items-center rounded-md">
						<Logo />
					</NavLink>
				</NavigationMenuLink>
			</ContextMenuTrigger>
			<ContextMenuContent>
				<ContextMenuItem onClick={() => window.open('https://verveui.pro', '_newtab')} className="border-muted-200 flex gap-3 border-b">
					<Icon name="external-link" />
					<span>Open link in New Tab</span>
				</ContextMenuItem>
				<ContextMenuItem
					onClick={() => {
						copy(logoAsSVG)
						toast.success('Logo copied to clipboard')
					}}
					className="flex gap-3"
				>
					<Icon name="copy" />
					<span>Copy logo as SVG</span>
				</ContextMenuItem>
				<ContextMenuItem asChild className="flex gap-3">
					<Link prefetch={prefetch} to="/brand">
						<Icon name="download" />
						<span>Go to Brand Kit</span>
					</Link>
				</ContextMenuItem>
			</ContextMenuContent>
		</ContextMenu>
	)
}

export function Logo() {
	return (
		<>
			<svg className="logo-dark-theme hidden" width="163" height="33" viewBox="0 0 163 33" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M45.96 22.5L38.94 8.3H43.44L47.88 17.84C48.14 18.4 48.26 18.84 48.36 19.28H48.44C48.52 18.84 48.66 18.4 48.9 17.84L53.34 8.3H57.86L50.82 22.5H45.96ZM63.6886 19.66H74.1286V22.5H59.6486V8.3H73.9886V11.14H63.6886V13.98H73.6486V16.62H63.6886V19.66ZM88.7663 22.5L85.3863 17.48H81.2862V22.5H77.2463V8.3H86.9263C91.4863 8.3 92.8063 10.64 92.8063 12.8C92.8063 14.38 92.0063 16.24 89.6263 17.02L93.3263 22.5H88.7663ZM81.2862 11V14.9H86.0063C87.9463 14.9 88.5263 13.92 88.5263 12.92C88.5263 11.94 87.9463 11 86.0063 11H81.2862ZM100.12 22.5L93.1002 8.3H97.6002L102.04 17.84C102.3 18.4 102.42 18.84 102.52 19.28H102.6C102.68 18.84 102.82 18.4 103.06 17.84L107.5 8.3H112.02L104.98 22.5H100.12ZM117.849 19.66H128.289V22.5H113.809V8.3H128.149V11.14H117.849V13.98H127.809V16.62H117.849V19.66ZM138.932 8.3H142.992V15.72C142.992 18.12 144.492 19.82 147.292 19.82C149.992 19.82 151.572 18.12 151.572 15.72V8.3H155.612V16.14C155.612 20.24 152.652 23 147.292 23C141.912 23 138.932 20.22 138.932 16.1V8.3ZM162.771 22.5H158.731V8.3H162.771V22.5Z"
					fill="url(#paint0_linear_3053_4067)"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M35.7139 4.90495C36.7855 3.03439 34.6556 0.514076 32.4879 0.500063L2.47934 0.500062C0.287078 0.485886 -0.633004 2.84004 0.463149 4.7248L15.7979 31.0917C16.894 32.9765 19.6375 32.9676 20.7213 31.0758L35.7139 4.90495ZM32.2828 6.1658C33.0785 4.76573 31.7065 2.88634 30.0872 2.87037L6.08037 2.87037C4.43297 2.85412 3.29625 4.52635 4.11999 5.94271L16.5638 27.3389C17.3876 28.7553 19.4504 28.7451 20.26 27.3207L32.2828 6.1658Z"
					fill="url(#paint1_linear_3053_4067)"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M28.7709 8.11544C29.4256 7.00479 28.5997 5.62477 27.2754 5.61645L8.74729 5.50004C7.40795 5.49162 6.56518 6.8894 7.23486 8.00848L16.6034 23.6638C17.2731 24.7829 18.9492 24.7776 19.6113 23.6544L28.7709 8.11544ZM26.6747 8.86407C27.1608 8.03277 26.5446 7.00308 25.5553 6.99359L10.6077 6.85031C9.60128 6.84067 8.96571 7.89065 9.46897 8.73161L17.0714 21.4356C17.5746 22.2766 18.8349 22.2705 19.3295 21.4248L26.6747 8.86407Z"
					fill="url(#paint2_linear_3053_4067)"
				/>
				<defs>
					<linearGradient id="paint0_linear_3053_4067" x1="102" y1="8.875" x2="102" y2="19.8125" gradientUnits="userSpaceOnUse">
						<stop stopColor="white" />
						<stop offset="1" stopColor="#B6B6B6" />
					</linearGradient>
					<linearGradient id="paint1_linear_3053_4067" x1="33.5" y1="1" x2="14.5" y2="25" gradientUnits="userSpaceOnUse">
						<stop stopColor="#B6B6B6" />
						<stop offset="1" stopColor="white" />
					</linearGradient>
					<linearGradient id="paint2_linear_3053_4067" x1="18" y1="24.5" x2="18" y2="5.5" gradientUnits="userSpaceOnUse">
						<stop stopColor="white" />
						<stop offset="1" stopColor="white" stopOpacity="0" />
					</linearGradient>
				</defs>
			</svg>
			<svg className="logo-light-theme hidden" width="163" height="33" viewBox="0 0 163 33" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M45.96 22.5L38.94 8.3H43.44L47.88 17.84C48.14 18.4 48.26 18.84 48.36 19.28H48.44C48.52 18.84 48.66 18.4 48.9 17.84L53.34 8.3H57.86L50.82 22.5H45.96ZM63.6886 19.66H74.1286V22.5H59.6486V8.3H73.9886V11.14H63.6886V13.98H73.6486V16.62H63.6886V19.66ZM88.7663 22.5L85.3863 17.48H81.2862V22.5H77.2463V8.3H86.9263C91.4863 8.3 92.8063 10.64 92.8063 12.8C92.8063 14.38 92.0063 16.24 89.6263 17.02L93.3263 22.5H88.7663ZM81.2862 11V14.9H86.0063C87.9463 14.9 88.5263 13.92 88.5263 12.92C88.5263 11.94 87.9463 11 86.0063 11H81.2862ZM100.12 22.5L93.1002 8.3H97.6002L102.04 17.84C102.3 18.4 102.42 18.84 102.52 19.28H102.6C102.68 18.84 102.82 18.4 103.06 17.84L107.5 8.3H112.02L104.98 22.5H100.12ZM117.849 19.66H128.289V22.5H113.809V8.3H128.149V11.14H117.849V13.98H127.809V16.62H117.849V19.66ZM138.932 8.3H142.992V15.72C142.992 18.12 144.492 19.82 147.292 19.82C149.992 19.82 151.572 18.12 151.572 15.72V8.3H155.612V16.14C155.612 20.24 152.652 23 147.292 23C141.912 23 138.932 20.22 138.932 16.1V8.3ZM162.771 22.5H158.731V8.3H162.771V22.5Z"
					fill="url(#paint0_linear_3053_4071)"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M35.7139 4.90495C36.7855 3.03439 34.6556 0.514076 32.4879 0.500063L2.47934 0.500062C0.287078 0.485886 -0.633004 2.84004 0.463149 4.7248L15.7979 31.0917C16.894 32.9765 19.6375 32.9676 20.7213 31.0758L35.7139 4.90495ZM32.2828 6.1658C33.0785 4.76573 31.7065 2.88634 30.0872 2.87037L6.08037 2.87037C4.43297 2.85412 3.29625 4.52635 4.11999 5.94271L16.5638 27.3389C17.3876 28.7553 19.4504 28.7451 20.26 27.3207L32.2828 6.1658Z"
					fill="#0A0A0A"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M28.7709 8.11544C29.4256 7.00479 28.5997 5.62477 27.2754 5.61645L8.74729 5.50004C7.40795 5.49162 6.56518 6.8894 7.23486 8.00848L16.6034 23.6638C17.2731 24.7829 18.9492 24.7776 19.6113 23.6544L28.7709 8.11544ZM26.6747 8.86407C27.1608 8.03277 26.5446 7.00308 25.5553 6.99359L10.6077 6.85031C9.60128 6.84067 8.96571 7.89065 9.46897 8.73161L17.0714 21.4356C17.5746 22.2766 18.8349 22.2705 19.3295 21.4248L26.6747 8.86407Z"
					fill="url(#paint1_linear_3053_4071)"
				/>
				<defs>
					<linearGradient id="paint0_linear_3053_4071" x1="102" y1="20.5" x2="102" y2="10.5" gradientUnits="userSpaceOnUse">
						<stop stopColor="#0A0A0A" />
						<stop offset="1" stopColor="#404042" />
					</linearGradient>
					<linearGradient id="paint1_linear_3053_4071" x1="18" y1="24.5" x2="18" y2="5.5" gradientUnits="userSpaceOnUse">
						<stop stopColor="#0A0A0A" />
						<stop offset="1" stopColor="#111111" stopOpacity="0" />
					</linearGradient>
				</defs>
			</svg>
		</>
	)
}

function UserDropdown({ user }: { user: SerializeFrom<typeof rootLoader>['user'] }) {
	const submit = useSubmit()
	const formRef = useRef<HTMLFormElement>(null)

	return (
		<DropdownMenu>
			<DropdownMenuTrigger className="text-size-sm hover:bg-muted-100 ml-auto hidden items-center gap-4 rounded-md py-3 px-4 font-semibold md:flex">
				<span className="font-normal">
					Account <span className="bg-muted-200 text-muted-400 ml-1 rounded-md py-0.5 px-1 text-xs">{user?.paid ? 'PRO' : 'FREE'}</span>
				</span>
				<Icon name="chevron-down" className="h-4 w-4" />
			</DropdownMenuTrigger>

			<DropdownMenuPortal>
				<DropdownMenuContent align="start">
					<DropdownMenuLabel className="border-muted-200 mb-1 border-b font-normal">
						<Flex orientation="vertical" className="text-xs">
							<span className="text-muted-500">Logged in as</span>
							<span className="text-muted-500">{user?.email}</span>
						</Flex>
					</DropdownMenuLabel>

					<DropdownMenuItem asChild>
						<Link
							prefetch={prefetch}
							to="/profile"
							className="data-[highlighted]:bg-muted-100 hover:bg-muted-100 text-muted-500 block flex w-full items-center justify-between rounded-md p-2 text-xs font-normal no-underline data-[disabled]:cursor-default"
						>
							Settings
						</Link>
					</DropdownMenuItem>
					<Form method="POST" action="/logout">
						<DropdownMenuItem
							asChild
							// this prevents the menu from closing before the form submission is completed
							onSelect={event => {
								event.preventDefault()
								submit(formRef.current)
							}}
						>
							<Button
								type="submit"
								variant="ghost"
								className="data-[highlighted]:bg-muted-100 hover:bg-muted-100 text-muted-500 block flex w-full items-center justify-between rounded-md p-2 text-xs font-normal no-underline data-[disabled]:cursor-default"
							>
								Logout
								<Icon name="exit" />
							</Button>
						</DropdownMenuItem>
					</Form>
				</DropdownMenuContent>
			</DropdownMenuPortal>
		</DropdownMenu>
	)
}
